import React, { useEffect, useState, useCallback } from "react";
import { QRCodeSVG } from "qrcode.react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

function DeviceAuth({ onAuthSuccess }) {
  const [deviceCode, setDeviceCode] = useState("");
  const [userCode, setUserCode] = useState("");
  const [verificationUri, setVerificationUri] = useState("");
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const pollForToken = useCallback(async (deviceCode, interval) => {
    try {
      const response = await fetch(`https://${domain}/oauth/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "urn:ietf:params:oauth:grant-type:device_code",
          device_code: deviceCode,
          client_id: clientId,
        }),
      });

      const data = await response.json();

      if (data.error === "authorization_pending") {
        const id = setTimeout(
          () => pollForToken(deviceCode, interval),
          interval * 1000
        );
        setTimeoutId(id);
        return;
      }

      if (data.error === "slow_down") {
        const id = setTimeout(
          () => pollForToken(deviceCode, interval * 2),
          interval * 2000
        );
        setTimeoutId(id);
        return;
      }

      if (data.error) {
        setError(data.error_description);
        if (data.error !== "expired_token" && data.error !== "access_denied") {
          const id = setTimeout(
            () => pollForToken(deviceCode, interval),
            interval * 1000
          );
          setTimeoutId(id);
        }
        return;
      }

      if (data.access_token) {
        localStorage.setItem(
          `@@auth0spajs@@::${clientId}::${audience}::openid profile email offline_access`,
          JSON.stringify({
            body: {
              access_token: data.access_token,
              refresh_token: data.refresh_token,
              id_token: data.id_token,
              token_type: "Bearer",
              expires_in: data.expires_in,
              scope: "openid profile email offline_access",
              decodedToken: {
                user: JSON.parse(atob(data.access_token.split(".")[1])),
              },
            },
            expiresAt: Math.floor(Date.now() / 1000) + data.expires_in,
          })
        );

        window.location.reload();
      }
    } catch (err) {
      setError("Failed to complete authentication");
      const id = setTimeout(
        () => pollForToken(deviceCode, interval),
        interval * 1000
      );
      setTimeoutId(id);
    }
  }, []);

  const startDeviceFlow = useCallback(async () => {
    try {
      const response = await fetch(`https://${domain}/oauth/device/code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: clientId,
          scope: "openid profile email offline_access",
          audience: audience,
        }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error_description);
        return;
      }

      setDeviceCode(data.device_code);
      setUserCode(data.user_code);
      setVerificationUri(data.verification_uri_complete);
      pollForToken(data.device_code, data.interval || 5);
    } catch (err) {
      setError("Failed to start device flow");
    }
  }, [pollForToken]);

  useEffect(() => {
    if (!deviceCode) {
      startDeviceFlow();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [deviceCode, timeoutId, startDeviceFlow]);

  return (
    <div
      className="device-auth"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      {deviceCode ? (
        <div style={{ textAlign: "center" }}>
          <h2>Device Authentication</h2>
          <div style={{ marginBottom: "20px" }}>
            <p>
              Your code:{" "}
              <strong
                style={{
                  fontSize: "24px",
                  letterSpacing: "2px",
                  padding: "10px",
                  background: "#f0f0f0",
                  borderRadius: "4px",
                }}
              >
                {userCode}
              </strong>
            </p>
          </div>

          <div style={{ marginBottom: "20px" }}>
            {verificationUri && (
              <QRCodeSVG
                value={verificationUri}
                size={256}
                level="H"
                includeMargin={true}
              />
            )}
          </div>

          <p>Scan the QR code or visit:</p>
          <p>
            <a href={verificationUri} target="_blank" rel="noopener noreferrer">
              {verificationUri}
            </a>
          </p>
          <p style={{ color: "#666" }}>Waiting for authentication...</p>
        </div>
      ) : (
        <div>Initializing device authentication...</div>
      )}
      {error && (
        <p style={{ color: "red" }} className="error">
          {error}
        </p>
      )}
    </div>
  );
}

export default DeviceAuth;
