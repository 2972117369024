import React from "react";
import FlightBanner from "./components/FlightBanner/FlightBanner";
import DeviceAuth from "./DeviceAuth";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div className="flight-banner-container">Loading...</div>;
  }

  return isAuthenticated ? <FlightBanner /> : <DeviceAuth />;
}

export default App;
