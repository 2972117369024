import "./FlightBanner.css";
import React, { useState, useEffect, useCallback } from "react";
import MOCK_FLIGHT_DATA from "../../flightData.json";
import logo from "../../assets/logo-final.png";
import WeatherData from "../../components/WeatherData";
import FlightDisplay from "../../components/FlightBanner/FlightDisplay";
import {
  API_ADDRESS,
  DEVELOPMENT_API_ADDRESS,
  DEFAULT_JSON_DATA,
} from "./constants/flightConstants.js";
import { useAuth0 } from "@auth0/auth0-react";

const DATA_SOURCE = "prod"; // Options: 'json', 'dev', 'prod'

const getApiUrl = (airport) => {
  switch (DATA_SOURCE) {
    case "json":
      return DEFAULT_JSON_DATA;
    case "dev":
      return `${DEVELOPMENT_API_ADDRESS}/${airport}`;
    case "prod":
    default:
      return `${API_ADDRESS}/${airport}`;
  }
};

const FlightBanner = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [flights, setFlights] = useState([]);
  const [currentFlightIndex, setCurrentFlightIndex] = useState(0);
  const [weatherCity, setCity] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchFlightData = useCallback(async () => {
    console.log("Fetching flight data...");
    try {
      const airport = getAirportFromUrl();
      console.log("Airport:", airport);
      let data;

      if (DATA_SOURCE === "json") {
        console.log("Using mock data");
        data = { flights: MOCK_FLIGHT_DATA };
      } else {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "wss://flyby.colonmelvin.com:8000",
            scope: "openid profile email offline_access",
          },
        });
        console.log("Got token, fetching from:", getApiUrl(airport));

        const response = await fetch(getApiUrl(airport), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch flight data: ${response.status}`);
        }
        data = await response.json();
      }

      console.log("Received data:", data);
      setCity(data.weather?.city);
      setFlights(data.flights || []);
      setError(null);
    } catch (error) {
      console.error("Error fetching flight data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchFlightData();
    const intervalId = setInterval(fetchFlightData, 300000); // 5 minutes
    return () => clearInterval(intervalId);
  }, [fetchFlightData]);

  useEffect(() => {
    if (flights.length > 0) {
      const flightSwitchInterval = setInterval(() => {
        setCurrentFlightIndex((prevIndex) => (prevIndex + 1) % flights.length);
      }, 5000);

      return () => clearInterval(flightSwitchInterval);
    }
  }, [flights]);

  if (loading) {
    return (
      <div className="flight-banner-container">Loading flight data...</div>
    );
  }

  if (error) {
    return <div className="flight-banner-container">Error: {error}</div>;
  }

  if (flights.length === 0) {
    return (
      <div className="flight-banner-container">No flight data available</div>
    );
  }

  const currentFlight = flights[currentFlightIndex];

  return (
    <div className="flight-banner-container">
      <div className="flight-banner-wrapper">
        <div className="flight-banner-content">
          <div className="logo-transition flight-banner-live logo">
            <img src={logo} alt="FlyBySpotter" className="flyby-text-banner" />
            <div className="live-banner-text">LIVE</div>
          </div>
        </div>
        <FlightDisplay currentFlight={currentFlight} />
        <WeatherData city={weatherCity} />
      </div>
    </div>
  );
};

const getAirportFromUrl = () => {
  const path = window.location.pathname;
  const airport = path.split("/").pop().toUpperCase();
  return airport || "RDU"; // Default to RDU if not specified
};

export default FlightBanner;
